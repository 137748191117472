import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { getCookie } from 'utils/cookie';

import Navbar from 'components/Layout/Navbar';
import MemberPage from 'pages/member';
import AreaPage from 'pages/area';
import LoginPage from 'pages/auth/login';
import TransactionPage from 'pages/transaction';
import Error404 from 'pages/Error404';


export default function Router() {
  const isAuth = getCookie('token');

  let element = useRoutes([
    {
      path: '/login',
      element: !isAuth ? <LoginPage /> : <Navigate to="/area" />,
    },
    {
      path: '/',
      element: <Navbar />,
      children: [
        {
          path: '',
          element: <Navigate to="area" />,
        },
        {
          path: 'member',
          element: isAuth ? <MemberPage /> : <Navigate to="/login" />,
        },
        {
          path: 'area',
          element: isAuth ? <AreaPage /> : <Navigate to="/login" />,
        },
                {
          path: 'transaction',
          element: isAuth ? <TransactionPage /> : <Navigate to="/login" />,
        },
      ],
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ]);
  return element;
}
