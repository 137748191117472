import { useState, useEffect, useRef, useMemo } from 'react';
import { Easing, Tween, update } from '@tweenjs/tween.js';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { mapState, viewportState, drawPolygonState, selectAreaState } from 'atoms/areaStates';
import { GOOGLE_MAP } from 'config';
import { LatLng } from 'types/area.types';
import { getGeoSpatial } from 'api/area';
import * as turf from '@turf/turf';
import Modal from 'components/common/Modal';
import { toast } from 'react-hot-toast';

const SEOUL_BOUNDS = {
  north: 37.71,
  south: 37.41,
  west: 126.73,
  east: 127.26,
};

const cameraOptions: google.maps.CameraOptions = {
  tilt: GOOGLE_MAP.animate.tilt,
  heading: GOOGLE_MAP.animate.heading,
  zoom: GOOGLE_MAP.animate.zoom,
  center: GOOGLE_MAP.initCenter,
};

const mapOptions: google.maps.MapOptions = {
  ...cameraOptions,
  // minZoom: 25,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: true,
  fullscreenControl: true,
  restriction: {
    latLngBounds: SEOUL_BOUNDS,
    strictBounds: false,
  },
  mapId: GOOGLE_MAP.id,
};

const toCameraOptions = {
  tilt: GOOGLE_MAP.animate.toTilt,
  heading: GOOGLE_MAP.animate.toHeading,
  zoom: GOOGLE_MAP.animate.toZoom,
};

// 애니메이션, 이벤트 처리 시간
const time = GOOGLE_MAP.loadingTime;

function Map() {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const setMapData = useSetRecoilState(mapState);
  const setViewport = useSetRecoilState(viewportState);
  const [selectArea, setSelectArea] = useRecoilState(selectAreaState);
  const [drawPolygon, setDrawPolygon] = useRecoilState(drawPolygonState);
  const [drawingManager, setDrawingManager] = useState<google.maps.drawing.DrawingManager | null>(
    null
  );
  const [eventTrigger, setEventTrigger] = useState<any>();
  const [polygonData, setPolygonData] = useState({ type: 'FeatureCollection', features: [] });

  // 모달
  const [modalShow, setModalShow] = useState<boolean>(false);

  // 두 좌표 사이의 거리
  const distance = useMemo(() => {
    // 각도를 라디안으로 변환
    function deg2rad(deg: number) {
      return deg * (Math.PI / 180);
    }

    return (lat1: number, lng1: number, lat2: number, lng2: number) => {
      const R = 6371; // 지구 반지름 (단위 : km)
      const dLat = deg2rad(lat2 - lat1);
      const dLng = deg2rad(lng2 - lng1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat1)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = Math.round(R * c * 1000); // 두 좌표 사이의 거리 (단위 : m)
      return distance;
    };
  }, []);

  // Drawing Tool
  const drawingTool = new google.maps.drawing.DrawingManager({
    drawingMode: null,
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
    },
    polygonOptions: {
      strokeColor: '#E74C3C',
      strokeOpacity: 0.75,
      strokeWeight: 3,
      fillColor: '#D2B4DE',
      fillOpacity: 0.5,
    },
  });

  useEffect(() => {
    if (mapRef.current && !map) {
      const newMap = new window.google.maps.Map(mapRef.current, mapOptions);
      setMap(newMap);
      setMapData(newMap);
      setDrawingManager(drawingTool);
    }

    // 지도 애니메이션
    // install Tweenjs with npm i @tweenjs/tween.js
    if (map !== undefined) {
      new Tween(cameraOptions)
        .to(toCameraOptions, time)
        .easing(Easing.Quadratic.Out)
        .onUpdate(() => {
          map.moveCamera(cameraOptions);
        })
        .start();

      const animate = (time: any) => {
        requestAnimationFrame(animate);
        update(time);
      };
      requestAnimationFrame(animate);
    }

    // 이벤트 및 스타일 처리
    map?.data.setStyle(styleFeature);
    map?.data.addListener('mouseover', mouseInToArea);
    map?.data.addListener('mouseout', mouseOutOfArea);
    map?.data.addListener('click', clickArea);
  }, [mapRef, map]);

  // 지도 중심 좌표 변경
  useEffect(() => {
    if (map) {
      let moveHandler: NodeJS.Timeout;

      const centerEvent = map?.addListener('center_changed', (e: any) => {
        // 이동 후 1~2초 경과 후 이벤트 처리
        clearTimeout(moveHandler);
        moveHandler = setTimeout(() => {
          fetchPolygonData();
        }, time);
      });

      // Zoom 레벨 변경
      const ZoomEvent = map?.addListener('zoom_changed', (e: any) => {
        clearTimeout(moveHandler);
        moveHandler = setTimeout(() => {
          fetchPolygonData();
        }, time);
      });

      return () => {
        google.maps.event.removeListener(centerEvent);
        google.maps.event.removeListener(ZoomEvent);
      };
    }
  }, [mapRef, map, selectArea]);

  useEffect(() => {
    if (drawPolygon) {
      setModalShow(true);
    }

    if (selectArea) {
      setTimeout(() => {
        applyStyle();
      }, 0);
    }
  }, [selectArea]);

  useEffect(() => {
    if (drawPolygon === null) {
      drawingManager && drawingManager.setOptions({ drawingControl: true });
    } else {
      drawingManager && drawingManager.setOptions({ drawingControl: false });
    }

    if (drawingManager) {
      drawingManager.setMap(map!);

      // 폴리곤 완료 이벤트 리스너
      const drawingEvent = google.maps.event.addListener(
        drawingManager,
        'polygoncomplete',
        (polygon: google.maps.Polygon) => {
          drawingManager.setDrawingMode(null);
          const polygonPaths = polygon?.getPath().getArray();

          if (polygonPaths) {
            const firstElement = polygonPaths.find((element) => element !== undefined);

            if (firstElement) {
              polygonPaths.push(firstElement);
            }
            const overlappingPolygon = checkForOverlap(polygonPaths, polygonData);

            if (overlappingPolygon) {
              toast.error(
                `기존 구역과 중복되어 신규 구역을 생성할 수 없습니다.\n빈 공간에서 다시 시도해주세요`,
                { duration: 4000 }
              );
              polygon.setMap(null);
            } else {
              map?.data.revertStyle();
              setDrawPolygon(polygon);
            }
          }
        }
      );
      return () => {
        google.maps.event.removeListener(drawingEvent);
      };
    }
  }, [drawPolygon, drawingManager, polygonData]);

  useEffect(() => {
    applyStyle();
  }, [eventTrigger]);

  // TODO geoJson 데이터 추가 후 스타일/이벤트 처리 함수
  const styleFeature = (feature: google.maps.Data.Feature): google.maps.Data.StyleOptions => {
    let style: google.maps.Data.StyleOptions = {
      strokeColor: '#EF233C',
      strokeOpacity: 0.1,
      strokeWeight: 2,
      fillColor: '#FDE2FF',
      fillOpacity: 0.1,
    };

    if (feature.getProperty('state') === 'hover') {
      style = {
        strokeColor: '#EF233C',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#8884FF',
        fillOpacity: 0.5,
      };
    }
    return style;
  };

  function mouseInToArea(e: google.maps.Data.MouseEvent): void {
    e.feature.setProperty('state', 'hover');
  }

  function mouseOutOfArea(e: google.maps.Data.MouseEvent): void {
    e.feature.setProperty('state', 'normal');
  }

  const clickArea = (e: any) => {
    map?.data.revertStyle();

    const feature = e.feature;
    feature.setProperty('state', 'normal');
    setSelectArea(feature.Jg);
    console.log('onClick : ', feature);
  };

  const fetchPolygonData = () => {
    const centerLatLng = map?.getCenter()?.toJSON() as LatLng;
    const northEastLatLng = map?.getBounds()?.getNorthEast().toJSON() as LatLng;

    if (centerLatLng && northEastLatLng) {
      const range = distance(
        northEastLatLng.lat,
        northEastLatLng.lng,
        centerLatLng.lat,
        centerLatLng.lng
      );
      setViewport({ centerLatLng, range });
      const viewportDistance = { centerLatLng, range };

      getGeoSpatial(viewportDistance).then((res) => {
        const result = res.data.features;

        if (result && result.length > 0) {
          // 기존 데이터와 새로운 데이터의 id를 모두 추출하여 Set에 저장
          const existingIds = new Set(
            polygonData.features.map((feature: any) => feature.properties.id)
          );
          const newIds = new Set(result.map((feature: any) => feature.properties.id));

          // 중복된 id를 제외한 모든 id를 포함하는 Set 생성
          const combinedIds = new Set([...existingIds, ...newIds]);

          // 중복 및 기존 데이터를 모두 제거한 새로운 features 배열 생성
          const updatedFeatures = result.filter((feature: any) =>
            combinedIds.has(feature.properties.id)
          );

          const updatedPolygonData = {
            ...polygonData,
            features: updatedFeatures,
          };

          // 새로운 features 배열로 polygonData를 업데이트
          setPolygonData(updatedPolygonData);
          map?.data.addGeoJson(updatedPolygonData, { idPropertyName: 'id' });
        }

        const trigger = map?.getCenter();
        setEventTrigger(trigger);
      });
    }
  };

  // 선택구역 색 변경 함수
  const applyStyle = () => {
    if (selectArea) {
      map?.data.forEach(function (feature: google.maps.Data.Feature) {
        if (feature.getId() === selectArea.id) {
          map?.data.overrideStyle(feature, {
            strokeColor: '#EF233C',
            strokeOpacity: 0.5,
            strokeWeight: 2,
            fillColor: '#BEF264',
            fillOpacity: 0.5,
          });
        }
      });
    }
  };

  // 폴리곤 그린 후 원래 구역과 겹치는지 확인
  const checkForOverlap = (newPolygonCoordinates: any, existingPolygonData: any) => {
    const newPolygon = turf.polygon([
      newPolygonCoordinates.map((latLng: any) => [latLng.lng(), latLng.lat()]),
    ]);

    try {
      map?.data.forEach((feature) => {
        let bounds: any = [];
        feature.getGeometry()!.forEachLatLng(function (path) {
          bounds.push(path);
        });

        const featurePaths = bounds.map((el: any) => {
          return [el.lng(), el.lat()];
        });

        const firstElement = featurePaths.find((element: any) => element !== undefined);
        if (firstElement) {
          featurePaths.push(firstElement);
        }

        const existingPolygon = turf.polygon([featurePaths]);

        if (turf.booleanOverlap(newPolygon, existingPolygon)) {
          throw new Error();
        }
      });
    } catch (e) {
      return true;
    }

    return false;
  };

  // 모달 관련 함수
  const handleMove = async () => {
    setModalShow(false);

    drawPolygon?.setMap(null);
    setDrawPolygon(null);
    drawingManager && drawingManager.setOptions({ drawingControl: true });
  };

  const handleCancel = () => {
    setModalShow(false);
    map?.data.revertStyle();
  };

  return (
    <>
      <div ref={mapRef} className="w-full h-[80vh]"></div>
      <Modal show={modalShow} setModalShow={setModalShow}>
        <div className="min-w-[400px] p-6 text-center">
          <h3 className="mt-5 mb-5 text-lg font-normal text-gray-500 whitespace-pre-line dark:text-gray-400">
            내용이 저장되지 않았습니다.
            <br />
            이동하시겠습니까?
          </h3>
          <button
            type="button"
            className="text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            onClick={handleMove}
          >
            네
          </button>
          <button
            type="button"
            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            onClick={handleCancel}
          >
            아니오
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Map;
