import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { getUser } from 'api/auth';
import { getCookie, removeCookie } from 'utils/cookie';
import { UserType } from 'types/auth.types';
import { FiLogOut } from 'react-icons/fi';
import { useRecoilState } from 'recoil';
import { authUserStates } from 'atoms/authStates';

interface Menu {
  name: string;
  path: string;
}

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useRecoilState<UserType | null>(authUserStates);
  const token = getCookie('token');

  useEffect(() => {
    if (token) {
      getUser().then((res) => {
        setUserData(res.data);
      });
    }
  }, []);

  const getMenuByRole = (): Menu[] => {
    const commonMenus: Menu[] = [{ name: '구역관리', path: '/area' }];
    const adminMenus: Menu[] = [
      ...commonMenus,
      { name: '매매관리', path: '/transaction' },
      { name: '회원관리', path: '/member' },
    ];
    return userData?.role === 'admin' ? adminMenus : commonMenus;
  };

  const menus: Menu[] = getMenuByRole();

  const handleLogout = (): void => {
    setUserData(null);
    removeCookie('token');
    navigate('/login');
  };

  return (
    <>
      <nav className="fixed top-0 bottom-0 left-0 z-10 flex-row items-center justify-between block py-4 overflow-hidden overflow-y-auto bg-white shadow-xl flex-nowrap w-60">
        <h1
          className="ml-3 block p-4 pb-10 text-2xl font-bold cursor-pointer whitespace-nowrap"
          onClick={() => navigate('/')}
        >
          CMS
        </h1>
        <div className="px-3 py-3 border-t border-b border-gray-300 max-hidden">
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <p className="ml-3 font-bold">{userData?.nickname}</p>
              <p className="ml-3 text-xs text-gray-400">{userData?.email}</p>
            </div>
            <div className="relative flex group">
              <button
                type="button"
                onClick={handleLogout}
                className="text-2xl text-neutral-500 logout-button-bounce"
              >
                <FiLogOut />
              </button>
              <span className="absolute p-0.5 text-xs w-[60px] text-center font-thin transition-opacity bottom-[120%] opacity-0 -right-2 rounded-md text-neutral-100 bg-neutral-700 group-hover:opacity-80">
                로그아웃
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col pt-2 sm:flex-row sm:justify-around max-hidden">
          <nav className="w-full px-5">
            <ul className="flex flex-col min-w-full list-none">
              {menus.map((menu: Menu, i: number) => (
                <li key={i}>
                  <a
                    className={` ${
                      location.pathname === menu.path ? 'bg-neutral-200' : ''
                    } flex items-center w-full py-2 px-3 my-2 text-base font-normal transition-colors duration-200 rounded-lg hover:bg-neutral-300`}
                    href="#"
                    onClick={() => navigate(menu.path)}
                  >
                    {menu.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </nav>
      <Outlet />
    </>
  );
}

export default Navbar;
