type IProps = {
  children: JSX.Element;
  title: string;
};

function Layout({ children, title }: IProps) {
  return (
    <main className="relative mx-auto ml-60">
      <div className="flex flex-col flex-1 h-screen px-5 overflow-y-auto transition duration-500 ease-in-out bg-gray-100">
        <div className="relative mt-14 max-w-[1600px] min-w-[1000px] w-full m-auto">
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">{title}</h2>
          <div className="w-full p-6 mt-6 bg-white shadow-xl rounded-xl">
            {children}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Layout;
