import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signin } from 'api/auth';
import useValid from 'hooks/useVaild';

function Login() {
  const navigate = useNavigate();
  const { user, emailChange, pwdChange, errMsg, isAnyInvalid } = useValid();
  const [loginErrMsg, setLginErrMsg] = useState<string | null>(null);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const newErrMsg = {
      email: errMsg.email,
      pwd: errMsg.pwd,
    };

    if (!isAnyInvalid(newErrMsg)) {
      emailChange(user.email);
      pwdChange(user.password);
    } else {
      signin(user)
        .then(() => {
          navigate('/area');
        })
        .catch((err) => {
          setLginErrMsg('로그인에 실패하였습니다. 다시 시도해주세요.');
        });
    }
  };

  const handleInputFocus = () => {
    setLginErrMsg(null);
  };

  return (
    <div className="absolute w-full m-auto top-1/2 -translate-y-3/4">
      <h1 className="mb-10 text-4xl font-bold text-center">CMS 로그인</h1>

      <form name="loginForm" className="flex flex-col justify-center mt-14" onSubmit={handleSubmit}>
        <label htmlFor="email" className="mt-4 mb-1 text-lg m-auto w-2/6 min-w-[300px] max-w-[500px]">
          이메일
        </label>
        <input
          type="text"
          name="email"
          id="email"
          className={`${
            errMsg.email?.valid === false ? 'border-red-400' : ''
          } m-auto w-2/6 min-w-[300px] max-w-[500px] h-11 px-2 py-4 border rounded-lg focus:outline-none focus:border-neutral-900`}
          onChange={(e) => emailChange(e.target.value)}
          value={user.email}
          onFocus={handleInputFocus}
          autoComplete="username"
        />
        {errMsg.email?.valid === false && (
          <p className="text-red-500 m-auto w-2/6 min-w-[300px] max-w-[500px]">{errMsg.email?.message}</p>
        )}
        <label htmlFor="pwd" className="mt-5 mb-1 text-lg m-auto w-2/6 min-w-[300px] max-w-[500px]">
          비밀번호
        </label>
        <input
          type="password"
          name="pwd"
          id="pwd"
          className={`${
            errMsg.pwd?.valid === false ? 'border-red-400' : ''
          } m-auto w-2/6 min-w-[300px] max-w-[500px] h-11 px-2 py-4 border rounded-lg focus:outline-none focus:border-neutral-900`}
          onChange={(e) => pwdChange(e.target.value)}
          value={user.password}
          onFocus={handleInputFocus}
          autoComplete="current-password"
        />
        {errMsg.pwd?.valid === false && (
          <p className="text-red-500 m-auto w-2/6 min-w-[300px] max-w-[500px]">{errMsg.pwd.message}</p>
        )}
        <button
          type="submit"
          className="h-12 mt-10 text-lg transition border rounded-lg shadow m-auto w-2/6 min-w-[300px] max-w-[500px] hover:bg-black hover:text-white hover:border-transparent"
        >
          로그인
        </button>
        {loginErrMsg && <p className="mt-1 text-sm text-red-500 m-auto w-2/6 min-w-[300px] max-w-[500px]">{loginErrMsg}</p>}
      </form>
    </div>
  );
}

export default Login;
